import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Select,
  MenuItem,
  TextField,
  Button,
  Chip,
  Switch,
  Collapse,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import moment from 'moment';
import ArrowForward from '@material-ui/icons/ArrowForwardTwoTone';
import { sendHttpCall, Toast } from 'shareService/httpService';
import { salesStages, selectoptions, selectSalesDatePickerOptions } from '../../config';
import { useLeadDrawer } from '../../contexts/LeadDrawerContext';
import { Tooltip } from '@material-ui/core';
import Loader from 'components/Loader';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    fontFamily: 'Poppins, sans-serif',
    overflowX: 'auto',
  },
  stagesContainer: {
    display: 'flex',
    overflowX: 'auto',
    margin: theme.spacing(2, -2),
    '&::-webkit-scrollbar': {
      height: 4,
      backgroundColor: '#f0f0f7',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8c959d',
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f7',
      borderRadius: 8,
    },
  },
  stageColumn: {
    minWidth: 300,
    maxWidth: 300,
    backgroundColor: '#f0f0f7',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflowY: 'auto',
    maxHeight: 600,
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8c959d',
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f7',
    },
  },
  timestamp: {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginLeft: theme.spacing(0.5),
  },
  nextFollowUpLabel: {
    color: '#000',
    fontSize: '0.875rem',
  },
  leadCard: {
    marginBottom: theme.spacing(2),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
  },
  opportunityCard: {
    marginBottom: theme.spacing(2),
    border: '1px solid #ccc',
    backgroundColor: '#e7ebff',
    borderRadius: theme.shape.borderRadius,
  },
  dataTypeTag: {
    display: 'block',
    textAlign: 'right',
    marginTop: theme.spacing(1),
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: '#297bca',
  },
  header: {
    marginBottom: theme.spacing(2),
    color: '#297bca',
    textAlign: 'center',
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: 400,
  },
  totalRevenueChip: {
    marginBottom: theme.spacing(2),
    fontSize: '0.8rem',
    fontWeight: 'bold',
    backgroundColor: '#297bca',
    color: '#fff',
    padding: theme.spacing(1),
    '& .MuiChip-outlinedPrimary': {
      borderColor: '#297bca',
    },
  },
  revenueChip: {
    fontSize: '0.7rem',
    backgroundColor: '#fff',
    fontWeight: 'bold',
    color: '#297bca',
    padding: theme.spacing(1),
    '& .MuiChip-outlinedPrimary': {
      borderColor: '#297bca',
    },
  },
  noData: {
    fontStyle: 'italic',
    color: '#666',
  },
  assignedText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  arrowIcon: {
    margin: '4px',
  },
  remarksCount: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#297bca',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    border: `1px solid #297bca`,
    cursor: 'help',
    textDecoration: 'none',
  },
  latestCard: {
    animation: '$gradientAnimation 3s infinite',
    background: 'linear-gradient(90deg, #a1c4fd, #c2e9fb, #a1c4fd)',
    backgroundSize: '200% 100%',
  },
  '@keyframes gradientAnimation': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '100%': {
      backgroundPosition: '100% 50%',
    },
  },
  searchField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    maxWidth: 400,
  },
  toggleButton: {
    textTransform: 'none',
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
  },
  goButton: {
    padding: '10px 0',
  },
  advancedFiltersContainer: {
    marginTop: theme.spacing(2),
  },
}));


const SalesDashboard = (props) => {
  const classes = useStyles();
  const [leadsData, setLeadsData] = useState([]);
  const [opportunitiesData, setOpportunitiesData] = useState([]);
  const [salesAdmins, setSalesAdmin] = useState([]);
  const [showInactiveAdmins, setShowInactiveAdmins] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(
    localStorage.getItem('user_type') === 'SALES' ? localStorage.getItem('admin_id') : ''
  );
  const [loading, setLoading] = useState(false);
  const today = moment(new Date()).format('YYYY-MM-DD');
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(today);
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [selectedPeriod, setSelectedPeriod] = useState('Today');
  const [selectedDataType, setSelectedDataType] = useState('ALL');
  const [selectedSortType, setSelectedSortType] = useState('NEXT_FOLLOW_UP_AT');
  const { openDrawer, isOpen } = useLeadDrawer();
  const [searchInput, setSearchInput] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const salesAdminsData = useSelector((state) => state.adminStaticConfig.data);

  const openUserPage = (userId) => {
    window.open(`/dashboard#/account/${userId}`, '_blank');
  };

  const handleDrawerOpen = (leadId) => {
    openDrawer(leadId);
  };

  const getLocaleTime = (time) => {
    if (time) return moment(new Date(time)).format('DD/MM/YYYY h:mm A');
  };

  const handleTimeChange = (event) => {
    const period = event.target.value;
    setSelectedPeriod(period);
    let endDate = new Date();
    let startDate = new Date();
  
    switch (period) {
      case 'All time':
        startDate = new Date(2019, 0, 1);
        break;
      case 'Last Year':
        startDate.setDate(endDate.getDate() - 365);
        break;
      case 'Last 30 days':
        startDate.setDate(endDate.getDate() - 30);
        break;
      case 'Last 7 days':
        startDate.setDate(endDate.getDate() - 7);
        break;
      case 'Yesterday':
        startDate.setDate(endDate.getDate() - 1);
        endDate.setDate(endDate.getDate() - 1);
        break;
      case 'Tomorrow':
        startDate.setDate(endDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);
        break;
      case 'Next 7 Days':
        startDate = new Date();  
        endDate.setDate(endDate.getDate() + 7);
        break;
      case 'Next 30 Days':
        startDate = new Date();  
        endDate.setDate(endDate.getDate() + 30);
        break;
      case 'This Month':
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
        break;
      case 'Previous Month':
        startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, 1);
        endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0);
        break;
      case 'Last 6 Months':
        startDate.setMonth(endDate.getMonth() - 6);
        break;
      default:
        break;
    }
  
    if (period !== 'Custom Date') {
      setIsCustomDate(false);
      const formattedStart = moment(startDate).format('YYYY-MM-DD');
      const formattedEnd = moment(endDate).format('YYYY-MM-DD');
      setSelectedStartDate(formattedStart);
      setSelectedEndDate(formattedEnd);
    } else {
      setIsCustomDate(true);
    }
  };
  

  const fetchData = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      sortType: selectedSortType,
      type: selectedDataType,
    });

    if (selectedAdminId) {
      queryParams.append('adminId', selectedAdminId);
    }

    if (searchInput.trim() !== '') {
      queryParams.append('search', searchInput.trim());
    }

    const query = `?${queryParams.toString()}`;

    try {
      const response = await sendHttpCall('GET', `sales/dashboard_data${query}`);
      setLeadsData(response.leads || []);
      setOpportunitiesData(response.opportunities || []);
    } catch (error) {
      Toast('Error fetching data');
    }
    setLoading(false);
  };

  const getSalesAdmins = () => {
    // const status = showInactiveAdmins ? 'INACTIVE' : 'ACTIVE';
    // let url = `admin/users/SALES/${status}`;
    // sendHttpCall('GET', url, null, props)
    //   .then((response) => {
    //     const formattedAdmins = response.data.map((admin) => ({
    //       label: `${admin.name} (${admin.email_id})`,
    //       value: admin.admin_id,
    //     }));
    //     setSalesAdmin(formattedAdmins);
    //   })
    //   .catch((error) => {
    //     console.log('Error fetching sales admins:', error);
    //   });
    // console.log("SalesAdminData", salesAdminsData);
    const formattedAdmins = salesAdminsData[!showInactiveAdmins ? 'allAdmins' : 'allInActiveAdmins'].map((admin) => ({
      label: `${admin.name} (${admin.email_id})`,
      value: admin.admin_id,
    }));
    setSalesAdmin(formattedAdmins);
  };

  useEffect(() => {
    if(salesAdminsData.loaded) {
      getSalesAdmins();
    }
  }, [showInactiveAdmins, salesAdminsData]);

  useEffect(() => {

    fetchData();
    getSalesAdmins();
  }, []);

  useEffect(() => {
    if (!isOpen) {
      fetchData();
    }

  }, [isOpen]);

  const handleAdminChange = (event) => {
    const adminId = event.target.value;
    setSelectedAdminId(adminId);
  };

  const handleTypeChange = (event) => {
    const type = event.target.value;
    setSelectedDataType(type);
  };

  const handleSortTypeChange = (event) => {
    const type = event.target.value;
    setSelectedSortType(type);
  };

  const handleToggleAdvancedFilters = () => {
    setShowAdvancedFilters((prev) => !prev);
  };

  const renderSalesAdminFilter = () => {
    if (localStorage.getItem('user_type') === 'SALES') {
      const userName = localStorage.getItem('user_name');
      return (
        <div style={{ padding: 16, backgroundColor: '#e7ebff', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h3" color="textPrimary">
            {`Hi, ${userName}!`}
          </Typography>
        </div>
      );
    } else {
      return (
        <Grid item xs={12} md={3}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color="textSecondary">
              SELECT SALES ADMIN
            </Typography>
            <Switch
              checked={showInactiveAdmins}
              onChange={() => {
                setShowInactiveAdmins(!showInactiveAdmins);
                getSalesAdmins();
              }}
              color="primary"
              style={{ marginLeft: 8 }}
            />
          </div>
          <Select
            value={selectedAdminId}
            onChange={handleAdminChange}
            displayEmpty
            fullWidth
            className={classes.select}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {!showInactiveAdmins && (
              <MenuItem value="">
                <em>All Admins</em>
              </MenuItem>
            )}
            {salesAdmins.map((admin) => (
              <MenuItem key={admin.value} value={admin.value}>
                {admin.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      );
    }
  };
  

  const renderStages = () => {
    return (
      <div>
        {loading ? (
          <div className={classes.stagesContainer}>
            <Loader />
          </div>
        ) : (
          <div className={classes.stagesContainer}>
            {salesStages.map((stage) => {
              const leadsDataStage =
                leadsData.find((data) => data.salesStageId === stage.value) || {};
              const opportunitiesDataStage =
                opportunitiesData.find((data) => data.salesStageId === stage.value) || {};
              const leadsCount = leadsDataStage.leads?.length || 0;
              const opportunitiesCount =
                opportunitiesDataStage.opportunities?.length || 0;
              const totalRevenue =
                (leadsDataStage.totalExpectedRevenue || 0) +
                (opportunitiesDataStage.totalExpectedRevenue || 0);
              const currency =
                leadsDataStage.leads?.[0]?.currency ||
                opportunitiesDataStage.opportunities?.[0]?.currency ||
                'INR';

              return (
                <div key={stage.value} className={classes.stageColumn}>
                  <Typography variant="h4">{`${stage.label}`}</Typography>
                  <Typography
                    style={{ paddingBottom: '5px' }}
                    color="textSecondary"
                    variant="h6"
                  >
                    {opportunitiesCount} Opportunities | {leadsCount} Leads
                  </Typography>
                  <Chip
                    label={`Total Expected: ${totalRevenue} ${currency || 'INR'}`}
                    color="primary"
                    variant="outlined"
                    className={classes.totalRevenueChip}
                  />
                  {renderStageContent(stage.value)}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderStageContent = (stageId) => {
    const leads =
      leadsData.find((data) => data.salesStageId === stageId)?.leads || [];
    const opportunities =
      opportunitiesData.find((data) => data.salesStageId === stageId)
        ?.opportunities || [];

    const getCurrentISTTime = () => {
      return moment().utcOffset('+05:30').toDate();
    };

    const getNextFollowUpItem = (data) => {
      if (!data || data.length === 0) return null;
      const currentTime = getCurrentISTTime();
      return data.reduce((closest, item) => {
        const nextFollowUp = item.nextFollowUpAt
          ? new Date(item.nextFollowUpAt)
          : null;
        if (nextFollowUp && nextFollowUp > currentTime) {
          const diff = nextFollowUp - currentTime;
          if (!closest || diff < closest.diff) {
            return { ...item, diff };
          }
        }
        return closest;
      }, null);
    };

    const flattenData = () => {
      let allItems = [];
      leadsData.forEach((data) => {
        allItems = [...allItems, ...data.leads];
      });
      opportunitiesData.forEach((data) => {
        allItems = [...allItems, ...data.opportunities];
      });
      return allItems;
    };

    const mostRecentGlobalItem = () => {
      const allItems = flattenData();
      return getNextFollowUpItem(allItems);
    };

    const recentItem = selectedAdminId ? mostRecentGlobalItem() : null;

    if (leads.length === 0 && opportunities.length === 0) {
      return (
        <Typography className={classes.noData}>No data for this stage.</Typography>
      );
    }

    return (
      <>
        {opportunities.map((opportunity, index) => (
          <Card
            key={index}
            className={`${classes.opportunityCard} ${
              recentItem && recentItem.nextFollowUpAt === opportunity.nextFollowUpAt
                ? classes.latestCard
                : ''
            }`}
            style={{ position: 'relative' }}
          >
            <CardContent>
              <Typography
                variant="subtitle1"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span
                  onClick={() =>
                    opportunity.userId && openUserPage(opportunity.userId)
                  }
                  style={{
                    cursor: opportunity.userId ? 'pointer' : 'default',
                    textDecoration: opportunity.userId ? 'underline' : 'none',
                    flex: 1,
                  }}
                >
                  Client: {opportunity.name}
                </span>
                <Tooltip
                  title={`Message count: ${opportunity.remarksCount}`}
                  placement="top"
                >
                  <div className={classes.remarksCount}>
                    {opportunity.remarksCount}
                  </div>
                </Tooltip>
              </Typography>
              <Typography variant="subtitle2">Company: {opportunity.companyName}</Typography>

              {/* <Typography variant="subtitle2">Assigned By:</Typography> */}
              <Typography className={classes.assignedText}>
                {opportunity.assignedByName}{' '}
                <ArrowForward className={classes.arrowIcon} />
                {opportunity.assignedToName}
              </Typography>
              {opportunity.expectedRevenue > 0 && (
                <Chip
                  label={`Expected: ${opportunity.expectedRevenue} ${
                    opportunity.currency || 'INR'
                  }`}
                  color="secondary"
                  variant="outlined"
                  className={classes.revenueChip}
                />
              )}
              {opportunity.nextFollowUpAt && (
                <Typography
                  variant="subtitle2"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span className={classes.nextFollowUpLabel}>Follow Up:</span>
                  <span className={classes.timestamp}>
                    {getLocaleTime(opportunity.nextFollowUpAt)}
                  </span>
                </Typography>
              )}
              <div className={classes.dataTypeTag}>Opportunity</div>
            </CardContent>
          </Card>
        ))}

        {leads.map((lead, index) => (
          <Card
            key={index}
            className={`${classes.leadCard} ${
              recentItem && recentItem.nextFollowUpAt === lead.nextFollowUpAt
                ? classes.latestCard
                : ''
            }`}
            style={{ position: 'relative' }}
          >
            <CardContent>
              <Typography
                variant="subtitle1"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span
                  onClick={() => lead.id && handleDrawerOpen(lead.id)}
                  style={{
                    cursor: lead.id ? 'pointer' : 'default',
                    textDecoration: lead.id ? 'underline' : 'none',
                    flex: 1,
                  }}
                >
                  Client: {lead.name}
                </span>
                <Tooltip title="Message count" placement="top">
                  <div className={classes.remarksCount}>{lead.remarksCount}</div>
                </Tooltip>
              </Typography>
              <Typography variant="subtitle2">Company: {lead.companyName}</Typography>

              {/* <Typography variant="subtitle2">Assigned By:</Typography> */}
              <Typography className={classes.assignedText}>
                {lead.assignedByName}
                <ArrowForward className={classes.arrowIcon} />
                 {lead.assignedToName}
              </Typography>
              <Chip
                label={`Expected: ${lead.expectedRevenue} ${lead.currency || 'INR'}`}
                color="secondary"
                variant="outlined"
                className={classes.revenueChip}
              />
              {lead.nextFollowUpAt && (
                <Typography
                  variant="subtitle2"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span className={classes.nextFollowUpLabel}>Follow Up:</span>
                  <span className={classes.timestamp}>
                    {getLocaleTime(lead.nextFollowUpAt)}
                  </span>
                </Typography>
              )}
              <div className={classes.dataTypeTag}>Lead</div>
            </CardContent>
          </Card>
        ))}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.header}>
        Sales Dashboard
      </Typography>

      <Grid container spacing={2} alignItems="flex-end">
        {/* Time Filter */}
        
        {/* Sales Admin Filter */}

          {renderSalesAdminFilter()}

        <Grid item xs={12} md={3}>
          <Typography variant="body2" color="textSecondary">
            SELECT PERIOD
          </Typography>
          <TextField
            fullWidth
            margin="dense"
            name="status"
            onChange={handleTimeChange}
            required
            select
            SelectProps={{ native: true }}
            value={selectedPeriod}
            variant="outlined"
          >
            {(selectedSortType === 'CREATED_AT'
              ? selectoptions
              : selectSalesDatePickerOptions
            ).map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </TextField>
        </Grid>

        {/* Search Bar */}
        <Grid item xs={12} md={3}>
          <Typography variant="body2" color="textSecondary">
            SEARCH BY NAME OR MOBILE
          </Typography>
          <TextField
            fullWidth
            margin="dense"
            name="search"
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            variant="outlined"
            placeholder="Enter name or mobile number"
            className={classes.searchField}
          />
        </Grid>


        {/* Go Button */}
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchData}
            fullWidth
            className={classes.goButton}
          >
            Go
          </Button>
        </Grid>
      </Grid>

      {/* Second Row: Advanced Filters Toggle */}
      <Grid container spacing={2} alignItems="center" style={{ marginTop: 16 }}>
        <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
          <Button
            variant="text"
            color="primary"
            onClick={handleToggleAdvancedFilters}
            className={classes.toggleButton}
            startIcon={showAdvancedFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            Advanced Filters
          </Button>
        </Grid>
      </Grid>

      {/* Advanced Filters Section */}
      <Collapse in={showAdvancedFilters}>
        <Grid container spacing={2} alignItems="flex-end" className={classes.advancedFiltersContainer}>
          {/* Select Type (Advanced Filter) */}
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary">
              SELECT TYPE
            </Typography>
            <Select
              value={selectedDataType}
              onChange={handleTypeChange}
              displayEmpty
              fullWidth
              className={classes.select}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="ALL">
                <em>All</em>
              </MenuItem>
              <MenuItem value="LEAD">Lead</MenuItem>
              <MenuItem value="OPPORTUNITIES">Opportunities</MenuItem>
            </Select>
          </Grid>

          {/* Select Date Type (Advanced Filter) */}
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary">
              SELECT DATE TYPE
            </Typography>
            <Select
              value={selectedSortType}
              onChange={handleSortTypeChange}
              displayEmpty
              fullWidth
              className={classes.select}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="NEXT_FOLLOW_UP_AT">Follow Ups</MenuItem>
              <MenuItem value="CREATED_AT">Created</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Collapse>

      {/* Stages Content */}
      <Grid container>{renderStages()}</Grid>
    </div>
  );
};

export default SalesDashboard;
