import { configureStore } from "@reduxjs/toolkit";
import adminStaticConfigReducer from "./reducer/adminStaticConfigReducer";
import staticConfig from './reducer/staticConfigReducer';

const store = configureStore({
    reducer: {
        adminStaticConfig: adminStaticConfigReducer,
        staticConfig: staticConfig
    }
});

export default store;