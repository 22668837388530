import React, { useEffect, useState } from 'react';
import PropTypes, { number } from 'prop-types';
import ReactModal from 'react-modal';
import { makeStyles } from '@material-ui/styles';
import ReactSelect from 'react-select/async-creatable';
import Select from 'react-select';
import Axios from 'axios';
import './styles.css';
import './custom.scss';
import moment from 'moment';
import { Paper, Typography, Divider } from '@material-ui/core';
import { sendHttpCall } from 'shareService/httpService';
import SendIcon from '@material-ui/icons/Send';
import { salesStages, salesIntents, currencyOptions } from '../../../../config';
import {
  DatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TimeDropDown from 'components/DateTimePicker/TimeDropdown';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import { useSelector } from 'react-redux';

const userPermissions = localStorage.getItem("permissions");

let timeoutId = null;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  details: {
    display: 'flex'
  },
  avatar: {
    height: 120,
    width: 120,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  score: {
    // margin: theme.spacing(3),
    border: '2px solid #3f51b5',
    borderRadius: 2,
    cursor: 'pointer',
    marginLeft: 10
  },

  datePicker: {
    backgroundColor: '#f5f5f5',
    borderColor: '#dcdcdc',
    boxShadow: 'none',
    borderRadius: '5px',
    width: 'auto',
    minHeight: '40px',
    border: '1px solid #f5f5f5',
    '& .MuiInputBase-root': {
      backgroundColor: '#f5f5f5',
      borderColor: '#dcdcdc',
      borderRadius: '4px',
      padding: '8px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#dcdcdc'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#b3b3b3'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#b3b3b3'
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none'
    },
    '& .MuiInputAdornment-root': {
      display: 'none' // Remove the calendar icon
    },
    '& .MuiInputBase-input': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#333'
    }
  }
}));

const UserAccountDetails = (props) => {
  const { className, userid, user, ...rest } = props;
  const [adminDetails, setAdminDetails] = useState({
    name: '',
    email: '',
    adminId: ''
  });
  const [salesAdmins, setSalesAdmin] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const [currentRemark, setCurrentRemark] = useState('');
  const [salesStageId, setSalesStageId] = useState(null);
  const [salesIntentId, setSalesIntentId] = useState(null);
  const [followUpDate, setFollowUpDate] = useState(null);
  const [noFollowUpNeeded, setNoFollowUpNeeded] = useState(false);
  const [currency, setCurrency] = useState('INR');
  const [expectedRevenue, setExpectedRevenue] = useState('');
  const [assignedToId, setAssignedToId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [pendingSalesStageId, setPendingSalesStageId] = useState(null);
  const [hiringFrequency, setHiringFrequency] = useState('');
const [targetedJobProfiles, setTargetedJobProfiles] = useState('');


  const adminStaticConfig = useSelector((state) => state.adminStaticConfig.data);

  const handleCurrencyChange = (option) => {
    setCurrency(option.value);
  };

  const handleExpectedRevenueChange = (event) => {
    setExpectedRevenue(event.target.value);
  };

  const showToast = (msg, type = 'success') => {
    setToastMessage({ msg, type });
  };

  const handleSalesStageChange = (option) => {
    if (option.value === 5) {
      setPendingSalesStageId(option.value);
      setShowConfirmationModal(true);
    } else {
      updateOpportunity({ selectedOption: option });
    }
  };

  const handleSalesIntentChange = (option) => {
      updateOpportunity({ selectedIntent: option });
  };

  const getSalesAdmins = (data) => {
    // let url = `admin/users/SALES`;
    // sendHttpCall('GET', url, null, props)
    //   .then((response) => {
    //     const formattedAdmins = [
    //       { label: 'SELF', value: 'self' },
    //       ...response.data.map((admin) => ({
    //         label: `${admin.name} (${admin.email_id})`,
    //         value: admin.admin_id
    //       })),
    //       { label: 'SoniaBot.ai', value: 'soniaBot', icon: './bot.png' }
    //     ];
    //     setSalesAdmin(formattedAdmins);
    //   })
    //   .catch((error) => {
    //     console.log('Error fetching sales admins:', error);
    //     showToast('Failed to fetch sales admins', 'error');
    //   });

    const formattedAdmins = [
      { label: 'SELF', value: 'self' },
      ...data.map((admin) => ({
        label: `${admin.name} (${admin.email_id})`,
        value: admin.admin_id
      })),
      { label: 'SoniaBot.ai', value: 'soniaBot', icon: './bot.png' }
    ];
    setSalesAdmin(formattedAdmins);

  };

  const hideToast = () => {
    setToastMessage('');
  };

  useEffect(() => {
    if (toastMessage) {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);

  useEffect(() => {
    const name = localStorage.getItem('user_name');
    const email = localStorage.getItem('user_email');
    const adminId = localStorage.getItem('admin_id');
    setAdminDetails({ name, email, adminId });
  }, []);
  
  useEffect(() => {
    if(adminStaticConfig.loaded) getSalesAdmins(adminStaticConfig['allAdmins']);
  }, [adminStaticConfig])

  console.log(user);

  const handleFollowUpDateChange = (date) => {
    setFollowUpDate(date);
  };

  const handleAssignToChange = (option) => {
    if (option.value === 'soniaBot') {
      showToast('Coming Soon!', 'info');
    } else {
      setAssignedToId(option.value);
      updateOpportunity({
        assignedToId: option.value.toString(),
        assignedById: adminDetails.adminId
      });
    }
  };

  const handleNoFollowUpChange = (event) => {
    setNoFollowUpNeeded(event.target.checked);
  };

  const onChangeRemark = (e) => {
    setCurrentRemark(e.target.value);
  };

  const saveRemarkToDb = () => {
    const now = new Date();
    const minFollowUpTime = new Date(now.getTime() + 2 * 60000); //2 mins

    console.log('Follow-up time', startTime);
    const newFollowupDate = new Date(followUpDate);
    //add the startTime to newFollowUpDate
    const [time, period] = startTime.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    newFollowupDate.setHours(hours);
    newFollowupDate.setMinutes(minutes);

    if (!currentRemark.trim()) {
      showToast('Remark should not be empty.', 'error');
      return;
    }
    if (!noFollowUpNeeded && startTime === 'Choose time') {
      showToast('Please select a follow-up time.', 'error');
      return;
    }

    if (
      !noFollowUpNeeded &&
      (!newFollowupDate || newFollowupDate <= minFollowUpTime)
    ) {
      showToast(
        'Please select a follow-up date and time at least 2 minutes from now.',
        'error'
      );
      return;
    }

    console.log('Saving to db:', currentRemark, ' - ', userid);
    let url = 'users/addRemark';
    sendHttpCall(
      'POST',
      url,
      {
        userId: userid,
        remark: currentRemark,
        nextFollowUpTime: noFollowUpNeeded ? null : newFollowupDate
      },
      props
    )
      .then((response) => {
        setCurrentRemark('');
        setFollowUpDate(new Date());
        setNoFollowUpNeeded(false);
        showToast('Remark saved successfully!', 'success');
        fetchUserParamerViewedScore(); // Refetch the data
      })
      .catch((error) => {
        showToast('Failed to save remark', 'error');
      });
  };

  const updateOpportunity = ({
    selectedOption,
    revenue,
    currency,
    assignedById,
    assignedToId,
    selectedIntent,
    selectedHiringFrequency,
    selectedTargetedJobProfiles
  }) => {
    let data = {};
 
    if (selectedOption) {
      data.salesStageId = selectedOption.value;
    }
    if (selectedIntent) {
      data.salesIntentId = selectedIntent.value;
    }
    if (revenue) {
      data.expectedRevenue = revenue;
      data.currency = currency || 'INR';
    }
    if (assignedById && assignedToId) {
      data.assignedById = assignedById;
      data.assignedToId = assignedToId;
    }

    if(selectedHiringFrequency) {
    data.hiringFrequency = selectedHiringFrequency
    }

    if(selectedTargetedJobProfiles) {
      data.targetedJobProfiles = selectedTargetedJobProfiles  
    }

    if (Object.keys(data).length > 0) {
      sendHttpCall(
        'POST',
        'sales/update_opportunity',
        { ...data, userId: userid },
        props
      )
        .then((response) => {
          showToast('Successfully updated opportunity details!', 'success');
          fetchUserParamerViewedScore();
        })
        .catch((error) => {
          console.log(error);
          if (error.message) {
            showToast(error.message, 'error');
          } else {
            showToast('Failed to update opportunity details!', 'error');
          }
        });
    } else {
      showToast('No changes to update', 'info');
    }
  };

  const getLocaleTime = (time) => {
    if (time) return moment(new Date(time)).format('DD/MM/YYYY h:mm A');
  };

  const renderRemarks = () => {
    if (!parameters.length || !parameters[0].remarks.length) return null;

    return (
      <div className="remarks__container">
        <div className="remarks-header">Conversation History</div>
        {parameters[0].remarks
          .filter((remark) => remark.remark)
          .map((remark) => (
            <div key={remark.remark_id} className="remark__item">
              <div className="remark__timestamp">
                {getLocaleTime(remark.date)}
              </div>
              <div className="remark__admin-name">
                {remark.admin_name ? <>{remark.admin_name}</> : 'Sales Team'}
              </div>
              <div className="remark__text">{remark.remark}</div>
              {remark.nextFollowUpAt && (
                <div className="next-follow-up">
                  Next Follow-up on:{' '}
                  <span className="next-follow-up-date">
                    {getLocaleTime(remark.nextFollowUpAt)}
                  </span>
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };

  const classes = useStyles();
  const [parameters, setParameters] = useState([]);
  const [showBlock, setShowBlock] = useState(false);
  // const [user, setUser] = useState({});
  const [open, setopen] = useState(false);
  const [candidatePreference, setCandidatePreference] = useState([]);
  const [confirmBlock, setConfirmBlock] = useState(false);
  const [purpose, setPurpose] = useState(' ');
  const [editingOrg, setEditingOrg] = useState(false);
  const [userStatus, setUserStatus] = useState(user.status);
  const [organisations, setOrganisations] = useState([]);
  const [organisationLoading, setOrganisationLoading] = useState(false);
  const [selectedHiringPurpose, setSelectedHiringPurpose] = useState({
    label: 'Self',
    value: 'Self'
  });
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [websiteVal, setWebsiteVal] = useState('');
  const [addressVal, setAddressVal] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [companySize, setCompanySize] = useState('');

  const rcStyle = {
    color: '#00b8e6',
    fontWeight: 'bold'
  };

  async function fetchUserParamerViewedScore() {
    sendHttpCall(
      'GET',
      `users/user_hiring_organisation/${props.userid}`,
      null,
      props
    ).then((response) => {
      setCandidatePreference(response.hirerCandidatePreference);
      setShowBlock(response.showOrg);
      setParameters(response.parameter);
      console.log(user)
      console.log(response.parameter);
      if (response.parameter.length) {
        if (response.parameter[0].organisation_id > 0) {
          setSelectedHiringPurpose({
            label: 'Company',
            value: 'Company'
          });
        }
        if (response.parameter[0].organisation_id === -2) {
          setSelectedHiringPurpose({
            label: 'Undisclosed Company',
            value: 'Undisclosed Company'
          });
        }
        setSelectedOrg({
          label: response.parameter[0].organisation,
          value: response.parameter[0].organisation_id
        });
        if (response.parameter[0].designation_id)
          setSelectedDesignation({
            label: response.parameter[0].designation,
            value: response.parameter[0].designation_id
          });
        if (response.parameter[0].website)
          setWebsiteVal(response.parameter[0].website);
        if (response.parameter[0].address)
          setAddressVal(response.parameter[0].address);
        if (response.parameter[0].linkedin_url) {
          setLinkedin(response.parameter[0].linkedin_url);
        }
        if (response.parameter[0].company_size) {
          setCompanySize(response.parameter[0].company_size);
        }
        if (
          response.parameter[0].opportunityData &&
          response.parameter[0].opportunityData.length
        ) {
          setSalesStageId(
            response.parameter[0].opportunityData[0].sales_stage_id
          );
          setSalesIntentId(
            response.parameter[0].opportunityData[0].sales_intent_id
          );
          setExpectedRevenue(
            response.parameter[0].opportunityData[0].expected_revenue
          );
          setCurrency(response.parameter[0].opportunityData[0].currency);
          setTargetedJobProfiles(response.parameter[0].opportunityData[0].targetedJobProfiles)
          setHiringFrequency(response.parameter[0].opportunityData[0].hiringFrequency)
          setAssignedToId(
            response.parameter[0].opportunityData[0].assignedToId
          );
        }
      }
    });
  }

  const handleBlock = async () => {
    sendHttpCall(
      'POST',
      'users/user_hiring_organisation/delete',
      {
        user_hiring_organisation_id: parameters[0].user_hiring_organisation_id
      },
      props
    )
      .then((response) => {
        window.location.reload(true);
      })
      .catch((e) => {
        alert('Something went wrong');
      });
  };
  const handleOpenDialog = () => {
    setopen(true);
  };

  const getDialogData = (data) => {
    if (data == 'x') {
      setopen(false);
    }
  };

  const fixedLenthString = (data) => {
    if (data.length > 0) {
      var d = data.substring(0, data.length / 3);
      return d;
    }
  };
  const getDesignation = async (val) => {
    const res = await Axios.get(
      'https://api.ourgoodspace.com/api/d2/config/searchConfig?config=designation&search=' +
        val,
      {
        headers: {
          Authorization:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTM1Nzg0MTEsImlhdCI6MTYzNDc5NTYyOX0.KZ3erXQQdtqNWZw4QLC3YzdA2GFdnI4s4h9O2Alsw9w'
        }
      }
    );
    let data = res.data.data.map((a) => ({
      label: a.value,
      value: a.id
    }));
    return data;
    // console.log(data);
    // setOrganisations(data);
    // return data;
  };
  const filterDesignation = async (inputValue) => {
    const data = await getDesignation(inputValue);
    return data;
  };
  const promiseOptions2 = async (inputValue) => {
    return await filterDesignation(inputValue);
  };
  const handleJobTitleChange = (val) => {
    let designation, designation_id;
    if (val.label) {
      designation_id = val.value;
      designation = val.label;
    } else {
      designation = val;
      designation_id = val;
    }
    console.log(val);
    setSelectedDesignation({
      value: designation_id,
      label: designation
    });
  };
  const getOrganisations = async (val) => {
    const res = await Axios.get(
      'https://api.ourgoodspace.com/api/d2/config/organisation?search=' + val
    );
    let data = res.data.data.map((a) => ({
      label: a.organisation,
      value: a.organisation_id
    }));
    return data;
    // console.log(data);
    // setOrganisations(data);
    // return data;
  };
  const filterOrganisation = async (inputValue) => {
    const data = await getOrganisations(inputValue);
    return data;
  };
  const promiseOptions = async (inputValue) => {
    return await filterOrganisation(inputValue);
  };
  const getLastFollowUp = () => {
    if (!parameters.length || !parameters[0].remarks.length) return null;
    const lastRemark = parameters[0].remarks.find((remark) => remark.remark);
    return lastRemark ? (
      <div className="last-follow-up">
        <div className="last-follow-up-admin">
          {lastRemark.admin_name ? <>{lastRemark.admin_name}</> : 'Sales Team'}
        </div>
        <div className="last-follow-up-timestamp">
          {new Date(lastRemark.date).toLocaleString()}
        </div>
        {lastRemark.nextFollowUpAt && (
          <div className="next-follow-up">
            Next Follow-up on:{' '}
            <span className="next-follow-up-date">
              {getLocaleTime(lastRemark.nextFollowUpAt)}
            </span>
          </div>
        )}
      </div>
    ) : null;
  };

  const handleOrgChange = (val) => {
    let organisation, organisation_id;
    if (val.label) {
      organisation_id = val.value;
      organisation = val.label;
    } else {
      organisation = val;
      organisation_id = val;
    }
    setSelectedOrg({
      value: organisation_id,
      label: organisation
    });
  };
  const toggleVerification = () => {
    sendHttpCall(
      'POST',
      'users/user_hiring_organisation/update_verification',
      {
        user_hiring_organisation_id: parameters[0].user_hiring_organisation_id,
        verified: parameters[0].verified === 0
      },
      props
    ).then((res) => window.location.reload(true));
  };
  const editOrg = () => {
    if (
      selectedHiringPurpose.label !== 'Self' &&
      selectedHiringPurpose.label !== 'Undisclosed Company' &&
      (!selectedOrg || (!selectedOrg.value && !selectedOrg.label))
    )
      return alert('Company name cannot be empty.');
    const userHiringOrganisation = {
      organisation:
        selectedHiringPurpose.label === 'Self' ||
        selectedHiringPurpose.label === 'Undisclosed Company'
          ? null
          : selectedOrg.label === selectedOrg.value
          ? selectedOrg.label
          : null,
      organisationId:
        selectedHiringPurpose.label === 'Self'
          ? -1
          : selectedHiringPurpose.label === 'Undisclosed Company'
          ? -2
          : selectedOrg.label === selectedOrg.value
          ? null
          : selectedOrg.value,
      designation: !selectedDesignation
        ? null
        : selectedDesignation.label === selectedDesignation.value
        ? selectedDesignation.label
        : null,
      designationId: !selectedDesignation
        ? null
        : selectedDesignation.label === selectedDesignation.value
        ? null
        : selectedDesignation.value,
      website: websiteVal,
      address: addressVal,
      user_hiring_organisation_id:
        parameters.length === 0
          ? null
          : parameters[0].user_hiring_organisation_id
    };

    sendHttpCall(
      'POST',

      'users/user_hiring_organisation/' +
        (parameters.length === 0 ? 'add' : 'update'),
      { userHiringOrganisation, userId: userid },
      props
    )
      .then((response) => {
        // setUserStatus(status1);
        window.location.reload(true);
      })
      .catch((e) => {
        alert('Something went wrong');
      });
  };
  const handlePurposeChange = (e) => {
    setSelectedHiringPurpose(e);
  };
  useEffect(() => {
    // fetchUserDetail();
    fetchUserParamerViewedScore();
  }, []);

  //

  const [startTime, setStartTime] = useState('Choose time');

  const generateTimeOptions = (start = '08:00 AM') => {
    const times = [];
    let startTime = new Date();
    startTime.setHours(8, 0, 0, 0); // Start at 8:00 AM

    for (let i = 0; i <= 51; i++) {
      // 51 intervals of 15 minutes from 8:00 AM to 8:30 PM
      const hours = startTime.getHours();
      const minutes = startTime.getMinutes();
      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const timeString = `${formattedHours}:${formattedMinutes} ${period}`;
      times.push(timeString);
      startTime.setMinutes(startTime.getMinutes() + 15);
    }

    return times.filter(
      (time) =>
        new Date(`1970/01/01 ${time}`) >= new Date(`1970/01/01 ${start}`)
    );
  };

  const handleStartTimeChange = (event) => {
    const newStartTime = event.target.value;
    const timeFormat = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;

    if (timeFormat.test(newStartTime)) {
      setStartTime(newStartTime);
      console.log('Start time on handle start time', newStartTime);
    } else {
      console.log('Invalid time format');
      showToast('Please enter a valid time in HH:MM AM/PM format.', 'error');
    }
  };

  //
  // if (!showBlock) return <div></div>;
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography gutterBottom variant="h4">
            User Hiring Organisation
            {parameters.length === 0
              ? ''
              : parameters[0].verified
              ? '(Verified)'
              : '(Unverified)'}
          </Typography>
          <Divider gutterBottom />
          <div className="org__container">
            <div className="org__container-left">
              {(parameters.length > 0 || editingOrg) && (
                <div className="org__data-container">
                  <div className="org__data-item">
                    <div className="org__data-item-label">Hiring For</div>
                    <div className="org__data-item-value">
                      {editingOrg ? (
                        <Select
                          value={selectedHiringPurpose}
                          onChange={handlePurposeChange}
                          options={[
                            { value: 'Self', label: 'Self' },
                            { value: 'Company', label: 'Company' },
                            {
                              value: 'Undisclosed Company',
                              label: 'Undisclosed Company'
                            }
                          ]}
                        />
                      ) : parameters.length ? (
                        parameters[0].organisation_id === -1 ? (
                          'Self'
                        ) : parameters[0].organisation_id === -2 ? (
                          'Undisclosed Company'
                        ) : (
                          'Company'
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="org__data-row">
                    <div className="org__data-item">
                      {(!parameters.length ||
                        parameters[0].organisation_id > 0) && (
                        <div className="org__data-item-label">Company</div>
                      )}
                      <div className="org__data-item-value">
                        {editingOrg ? (
                          <ReactSelect
                            value={selectedOrg}
                            cacheOptions
                            isDisabled={
                              selectedHiringPurpose.label === 'Self' ||
                              selectedHiringPurpose.label ===
                                'Undisclosed Company'
                            }
                            onChange={handleOrgChange}
                            loadOptions={promiseOptions}
                            onCreateOption={handleOrgChange}
                          />
                        ) : parameters.length ? (
                          parameters[0].organisation
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="org__data-item">
                      {(!parameters.length ||
                        parameters[0].organisation_id > 0) && (
                        <div className="org__data-item-label">Designation</div>
                      )}
                      <div className="org__data-item-value">
                        {editingOrg ? (
                          <ReactSelect
                            cacheOptions
                            value={selectedDesignation}
                            isDisabled={
                              selectedHiringPurpose.label === 'Self' ||
                              selectedHiringPurpose.label ===
                                'Undisclosed Company'
                            }
                            onChange={handleJobTitleChange}
                            loadOptions={promiseOptions2}
                            onCreateOption={handleJobTitleChange}
                          />
                        ) : parameters.length && parameters[0].designation ? (
                          parameters[0].designation
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="org__data-row">
                    <div className="org__data-item">
                      {(!parameters.length ||
                        parameters[0].organisation_id > 0) && (
                        <div className="org__data-item-label">Website</div>
                      )}
                      <div className="org__data-item-value">
                        {editingOrg ? (
                          <input
                            type="text"
                            readOnly={selectedHiringPurpose.label === 'Self'}
                            value={websiteVal}
                            onChange={(e) => setWebsiteVal(e.target.value)}
                          />
                        ) : parameters.length && parameters[0].website ? (
                          parameters[0].website
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="org__data-item">
                      {(!parameters.length ||
                        parameters[0].organisation_id > 0) && (
                        <div className="org__data-item-label">Address</div>
                      )}
                      <div className="org__data-item-value">
                        {editingOrg ? (
                          <input
                            readOnly={selectedHiringPurpose.label === 'Self'}
                            type="text"
                            value={addressVal}
                            onChange={(e) => setAddressVal(e.target.value)}
                          />
                        ) : parameters.length > 0 && parameters[0].address ? (
                          parameters[0].address
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="org__data-row">
                    <div className="org__data-item">
                      {parameters.length > 0 && (
                        <div className="org__data-item-label">Linkedin URL</div>
                      )}
                      <div className="org__data-item-value">
                        {editingOrg ? (
                          <input
                            readOnly={selectedHiringPurpose.label === 'Self'}
                            type="text"
                            value={linkedin}
                            onChange={(e) => setLinkedin(e.target.value)}
                          />
                        ) : parameters.length > 0 &&
                          parameters[0].linkedin_url ? (
                          parameters[0].linkedin_url
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="org__data-item">
                      {(!parameters.length ||
                        parameters[0].organisation_id > 0) && (
                        <div className="org__data-item-label">Company Size</div>
                      )}
                      <div className="org__data-item-value">
                        {false && editingOrg ? (
                          <input
                            readOnly={selectedHiringPurpose.label === 'Self'}
                            type="text"
                            value={linkedin}
                            onChange={(e) => setLinkedin(e.target.value)}
                          />
                        ) : parameters.length > 0 &&
                          parameters[0].company_size ? (
                          parameters[0].company_size.replace('employees', '') +
                          ' employees'
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                   {user.status ===3 && <>
                  <div className="org__data-row">
                    {/* <div className="org__data-item">
                      {parameters.length > 0 &&
                        parameters[0].opportunityData[0].forecasted_revenue && (
                          <>
                            <div className="org__data-item-label">
                              Forecasted Revenue
                            </div>
                            <div className="org__data-item-value">
                              {`${parameters[0].opportunityData[0].currency} ${parameters[0].opportunityData[0].forecasted_revenue}`}
                            </div>
                          </>
                        )}
                    </div> */}
                    <div className="org__data-item">
                      <div className="org__data-item-label">Sales Stage</div>
                      <div className="org__data-item-value">
                        <>
                          <Select
                            style={{ width: '150px' }}
                            value={salesStages.find(
                              (stage) => stage.value === salesStageId
                            )}
                            onChange={handleSalesStageChange} // Use the new function
                            options={salesStages}
                            placeholder="Select Sales Stage"
                          />

                          {parameters[0].opportunityData[0] && (
                            <div className="sales-stage-admin">
                              Stage Updated by:{' '}
                              {
                                parameters[0].opportunityData[0]
                                  .stageUpdatedByName
                              }
                              <div className="sales-stage-date">
                                @{' '}
                                {new Date(
                                  parameters[0].opportunityData[0].stageUpdatedAt
                                ).toLocaleString()}
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    </div>

                    {userPermissions?.includes("assign-opportunity") && <div className="org__data-item">
                      <div className="org__data-item-label">Assign To</div>
                      <div className="org__data-item-value">
                        <div
                          onClick={() => {
                            if (salesStageId === null) {
                              showToast(
                                'Please select the sales stage first.',
                                'info'
                              );
                            }
                          }}>
                          <Select
                            options={salesAdmins}
                            onChange={handleAssignToChange}
                            value={salesAdmins.find(
                              (option) => option.value === assignedToId
                            )}
                            isDisabled={salesStageId === null} // Disable if no salesStageId
                            placeholder="Select Admin"
                          />

                          {parameters[0].opportunityData[0] &&
                            parameters[0].opportunityData[0].assignedByName && (
                              <div className="sales-stage-admin">
                                Assigned by:{' '}
                                {
                                  parameters[0].opportunityData[0]
                                    .assignedByName
                                }
                                <div className="sales-stage-date">
                                  @{' '}
                                  {new Date(
                                    parameters[0].opportunityData[0].assignedAt
                                  ).toLocaleString()}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="org__data-row">
                    <div className="org__data-item">
                      <div className="org__data-item-label">Sales Intent</div>
                      <div className="org__data-item-value">
                        <>
                          <Select
                            style={{ width: '150px' }}
                            value={salesIntents.find(
                              (intent) => intent.value === salesIntentId
                            )}
                            onChange={handleSalesIntentChange} 
                            options={salesIntents}
                            placeholder="Select Sales Intent"
                          />

                        {parameters[0].opportunityData[0] &&
                            parameters[0].opportunityData[0].intentUpdatedByName && (
                              <div className="sales-stage-admin">
                                Intent Updated by:{' '}
                                {
                                  parameters[0].opportunityData[0]
                                    .intentUpdatedByName
                                }
                                <div className="sales-stage-date">
                                  @{' '}
                                  {new Date(
                                    parameters[0].opportunityData[0].intentUpdatedAt
                                  ).toLocaleString()}
                                </div>
                              </div>
                            )}
                        </>
                      </div>
                    </div>
                    <div className="org__data-item">
                      <div className="org__data-item-label">
                        Expected Revenue
                      </div>
                      <div className="org__data-item-value">
                        <div className="revenue-update-container">
                          <div className="input-group">
                            <Select
                              className="currency-select"
                              options={currencyOptions}
                              onChange={handleCurrencyChange}
                              defaultValue={{ label: '₹ INR', value: 'INR' }}
                            />
                            <input
                              className="revenue-input"
                              type="number"
                              placeholder="Enter revenue"
                              onChange={handleExpectedRevenueChange}
                              value={expectedRevenue}
                            />
                          </div>
                          <button
                            onClick={() =>
                              updateOpportunity({
                                revenue: expectedRevenue,
                                currency: currency
                              })
                            }
                            className="update-revenue-btn">
                            Update Revenue
                          </button>
                          {parameters[0].opportunityData[0] &&
                            parameters[0].opportunityData[0]
                              .revenueUpdatedByName && (
                              <div className="sales-stage-admin">
                                Revenue updated by:{' '}
                                {
                                  parameters[0].opportunityData[0]
                                    .revenueUpdatedByName
                                }
                                <div className="sales-stage-date">
                                  @{' '}
                                  {new Date(
                                    parameters[0].opportunityData[0].revenueUpdatedAt
                                  ).toLocaleString()}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div className="org__data-row">
  <div className="org__data-item">
     {/* Hiring Frequency Field */}
     <div className="hiring-frequency-field">
      <div className="org__data-item-label">Hiring Frequency</div>
      <input
        type="text"
        id="hiringFrequency"
        value={hiringFrequency}
        onChange={(e) => setHiringFrequency(e.target.value)}
        placeholder="eg.., 40 candidates per month / quarter"
      />
      {parameters[0].opportunityData[0] &&
        parameters[0].opportunityData[0].hiringFrequencyUpdatedByName && (
          <div className="sales-stage-admin">
            Updated by:{' '}
            {parameters[0].opportunityData[0].hiringFrequencyUpdatedByName}
            <div className="sales-stage-date">
              @{' '}
              {new Date(
                parameters[0].opportunityData[0].hiringFrequencyUpdatedAt
              ).toLocaleString()}
            </div>
          </div>
      )}
      <button
        onClick={() =>
          updateOpportunity({
            selectedHiringFrequency: hiringFrequency,
          })
        }
        className="update-hiring-frequency-btn"
      >
        Save Hiring Frequency
      </button>
    </div>

    {/* {parameters[0].remarks.length > 0 && (
      <div className="org__data-item-label">Last Follow Up</div>
    )}
    <div className="org__data-item-value">{getLastFollowUp()}</div> */}
  </div>

  <div className="org__data-item">
    {/* Targeted Job Profiles Field */}
    <div className="targeted-job-profiles-field">
      <div className="org__data-item-label">Targeted Job Profiles</div>
      <input
        type="text"
        id="targetedJobProfiles"
        value={targetedJobProfiles}
        onChange={(e) => setTargetedJobProfiles(e.target.value)}
        placeholder="e.g., Marketing Specialist, Sales Manager"
      />
      {parameters[0].opportunityData[0] &&
        parameters[0].opportunityData[0].targetedJobProfilesUpdatedByName && (
          <div className="sales-stage-admin">
            Updated by:{' '}
            {parameters[0].opportunityData[0].targetedJobProfilesUpdatedByName}
            <div className="sales-stage-date">
              @{' '}
              {new Date(
                parameters[0].opportunityData[0].targetedJobProfilesUpdatedAt
              ).toLocaleString()}
            </div>
          </div>
      )}
      <button
        onClick={() =>
          updateOpportunity({
            selectedTargetedJobProfiles: targetedJobProfiles,
          })
        }
        className="update-targeted-job-profiles-btn"
      >
        Save Targeted Job Profiles
      </button>
    </div>

</div>

</div>

                  </>
                        }
                </div>
              )}
              <div className="org__buttons">
                <button
                  className="org__buttons-btn org__buttons-btn-action"
                  onClick={() => {
                    if (editingOrg) {
                      editOrg();
                    } else if (parameters.length > 0) toggleVerification();
                    else setEditingOrg(true);
                  }}>
                  {editingOrg
                    ? 'Save'
                    : parameters.length === 0
                    ? 'Add'
                    : parameters[0].verified
                    ? 'Set as Unverified'
                    : 'Set as Verified'}
                </button>
                {!editingOrg &&
                  parameters.length > 0 &&
                  !parameters[0].verified && (
                    <>
                      <button
                        className="org__buttons-btn org__buttons-btn-edit"
                        onClick={() => setEditingOrg(!editingOrg)}>
                        Edit
                      </button>
                      <button
                        className="org__buttons-btn org__buttons-btn-delete"
                        onClick={() => setConfirmBlock(true)}>
                        Delete
                      </button>
                    </>
                  )}
              </div>
            </div>
            <div className="org__container-right">
              {renderRemarks()}
              <div className="remark-input-container">
                <label className="remark__admin-name" htmlFor="remarkInput">
                  {adminDetails.name} ({adminDetails.email})
                </label>
                <div className="remark-input-box">
                  <textarea
                    id="remarkInput"
                    name="remark"
                    value={currentRemark}
                    onChange={onChangeRemark}
                    className="remark-input"
                  />
                  <button onClick={saveRemarkToDb} className="send-button">
                    <SendIcon style={{ color: '#297bca' }} />
                  </button>
                </div>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    className={classes.datePicker}
                    margin="normal"
                    id="next-follow-up-date-picker"
                    format="dd/MM/yyyy"
                    placeholder="Select follow-up Date"
                    value={followUpDate}
                    onChange={handleFollowUpDateChange}
                    disabled={noFollowUpNeeded}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    disablePast
                  />
                </MuiPickersUtilsProvider>

                <TimeDropDown
                  value={startTime}
                  variant="soft"
                  onChange={handleStartTimeChange}
                  options={generateTimeOptions()}
                />

                <div
                  style={{
                    display: 'flex',
                    marginTop: '20px',
                    alignItems: 'left',
                    justifyContent: 'stretch'
                  }}></div>
                <div className="no-follow-up-checkbox">
                  <input
                    type="checkbox"
                    id="noFollowUpNeeded"
                    checked={noFollowUpNeeded}
                    onChange={handleNoFollowUpChange}
                  />
                  <label htmlFor="noFollowUpNeeded">No follow-up needed</label>
                </div>
              </div>
            </div>
          </div>
        </Paper>
        <ReactModal
          isOpen={confirmBlock}
          className="modal-class"
          overlayClassName="overlay-class">
          <div className="modalContainer">
            Are you sure you want to delete this hiring organisation?
            <div className="modalBtns">
              <button className="modal-accept" onClick={handleBlock}>
                Delete
              </button>
              <button
                className="modal-cancel"
                onClick={() => setConfirmBlock(false)}>
                Cancel
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography gutterBottom variant="h4">
            User Candidate Preference
          </Typography>
          <Divider gutterBottom />
          <div className="org__container">
            {candidatePreference &&
              !!candidatePreference.length &&
              candidatePreference.map((a, index) => (
                <div className="preference__list-item">
                  <div className="preference__list-item-no">{index + 1}.</div>
                  <div>
                    <div className="preference__list-item-value">
                      <span className="preference__list-item-label">
                        {' '}
                        Skills :{' '}
                      </span>
                      {a.skills.map((item) => item.value).join(', ')}
                    </div>
                    {a.resourcesRequired && (
                      <div className="preference__list-item-value">
                        <span className="preference__list-item-label">
                          {' '}
                          Resources Required :{' '}
                        </span>
                        {a.resourcesRequired
                          .map((item) => item.value)
                          .join(', ')}
                      </div>
                    )}
                    {a.reqExperience && (
                      <div className="preference__list-item-value">
                        <span className="preference__list-item-label">
                          {' '}
                          Req Experience :{' '}
                        </span>
                        {a.reqExperience.totalExpYears}{' '}
                        {a.reqExperience.totalExpYears === 1 ? 'year' : 'years'}
                      </div>
                    )}
                    {a.compensation && (
                      <div className="preference__list-item-value">
                        <span className="preference__list-item-label">
                          {' '}
                          Compensation :{' '}
                        </span>
                        {getCompensationFromIdNew(
                          a.compensation.monthly_compensation_id ||
                            a.compensation.hourly_compensation_id
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
          {toastMessage && (
            <div className={`toast ${toastMessage.type}`}>
              {toastMessage.msg}
            </div>
          )}
        </Paper>
      </div>

      <ReactModal
        isOpen={showConfirmationModal}
        className="modal-class"
        overlayClassName="overlay-class">
        <div className="modalContainer">
          <Typography variant="h6" gutterBottom>
            Are you sure the revenue generated by this opportunity was converted
            by you?
          </Typography>
          <div className="modalBtns">
            <button
              className="modal-accept"
              onClick={() => {
                updateOpportunity({
                  selectedOption: { value: pendingSalesStageId }
                });
                setShowConfirmationModal(false);
              }}>
              Confirm
            </button>
            <button
              className="modal-cancel"
              onClick={() => setShowConfirmationModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

UserAccountDetails.propTypes = {
  className: PropTypes.string
};

export default UserAccountDetails;

const getCompensationFromIdNew = (id) => {
  switch (id) {
    case 1:
      return '1.2 - 3 LPA';
    case 2:
      return '3 - 6 LPA';
    case 3:
      return '6 - 9 LPA';
    case 4:
      return '9 - 12 LPA';
    case 5:
      return '12 - 18 LPA';
    case 6:
      return '18 - 24 LPA';
    case 7:
      return '24 - 36 LPA';
    case 8:
      return '36 - 60 LPA';
    case 9:
      return '>60 LPA';
    case 10:
      return '₹ 100 - 200';
    case 11:
      return '₹ 200 - 350';
    case 12:
      return '₹ 350 - 500';
    case 13:
      return '₹ 500 - 700';
    case 14:
      return '₹ 700 - 1000';
    case 15:
      return '₹ 1k - 1.5k';
    case 16:
      return '₹ 1.5k - 2k';
    case 17:
      return '₹ 2k - 3k';
    case 18:
      return '₹ 3k - 5k';
    case 19:
      return '> ₹ 5k';
    default:
      return '';
  }
};
